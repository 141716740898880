var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: {
        title: _vm.$t("common.close-text", { text: _vm.$t("lbl_amortization") })
      }
    },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 9 },
            "data-testid": "close-amortization-detail-form"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_amortization_number"),
                      "data-testid": "close-amortization-detail-number"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_transaction_type"),
                      "data-testid":
                        "close-amortization-detail-transaction-type"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_unit_code"),
                      "data-testid": "close-amortization-detail-unit-code"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_closing_date"),
                      "data-testid": "close-amortization-detail-closing-date"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_description"),
                      "data-testid": "close-amortization-detail-description"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_leasing_number"),
                      "data-testid": "close-amortization-detail-leasing-number"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_leasing_contract_number"),
                      "data-testid": "close-amortization-detail-contract-number"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_notes"),
                      "data-testid": "close-amortization-detail-notes"
                    }
                  }),
                  _c("a-form-model-item", {
                    attrs: {
                      label: _vm.$t("lbl_journal_number"),
                      "data-testid": "close-amortization-detail-journal-number"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("a-table", {
                    attrs: {
                      size: "small",
                      "data-source": [],
                      columns: _vm.columns,
                      scroll: { x: 1200 },
                      "row-class-name": function(_record, index) {
                        return index % 2 ? "bg-white" : "bg-gray-light"
                      },
                      "data-testid": "close-amortization-detail-table"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "data-testid": "close-amortization-detail-btn-back"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }